import * as s from "./NavMenu.module.scss"

import Container from "components/shared/Container"
import Logo from "components/shared/Logo"
import Button from "components/shared/buttons/Button"

import MenuIcon from "./assets/MenuIcon.svg"

import useCTA from "utils/hooks/useCTA"

const NavMenu = () => {
    const navigateToForm = useCTA()

    return (
        <div className={s.wrapper}>
            <Container className={s.containerWrapper}>
                <Logo/>
                <div className={s.btnWrapper}>
                    <Button transparent color="white" onClick={navigateToForm}>Зв’язатись</Button>
                    {/*<Button transparent color="white" icon={<MenuIcon className={s.btnMenuIcon}/>}>Меню</Button>*/}
                </div>
            </Container>
        </div>
    )
}

export default NavMenu