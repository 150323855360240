import * as s from "./Logo.module.scss"

import LogoSVG from "images/main-logo.svg"

const Logo = () => {
    return (
        <LogoSVG className={s.logo}/>
    )
}

export default Logo