export const config = {
    settings: {
        mainFormID: "form",
    },
    serviceSection: [
        {
            title: "dAPPS",
            services: [
                {
                    title: "Фінансові додатки (DeFi)",
                    url: "#"
                },
                {
                    title: "Створення і оптимізація блокчейн-мереж",
                    url: "#"
                },
                {
                    title: "Розробка Смарт-контрактів",
                    url: "#"
                },
                {
                    title: "Аудит та безпека",
                    url: "#"
                },
                {
                    title: "DAO",
                    url: "#"
                },
            ],
        },
        {
            title: "WEB-DEV",
            services: [
                {
                    title: "Розробка сайтів (Mobile first)",
                    url: "#"
                },
                {
                    title: "Розробка мобільних додатків",
                    url: "#"
                },
                {
                    title: "Бекенд розробка",
                    url: "#"
                },
                {
                    title: "Створення AI рішень",
                    url: "#"
                },
                {
                    title: "Розробка Браузерних розширень",
                    url: "#"
                },
            ],
        },
        {
            title: "DESIGN",
            services: [
                {
                    title: "Рішення від брендингу до айдентики",
                    url: "#"
                },
                {
                    title: "Веб-дизайн та UI/UX дизайн",
                    url: "#"
                },
                {
                    title: "Редизайн існуючих брендів та продуктів",
                    url: "#"
                },
                {
                    title: "Графічний та 3D дизайн",
                    url: "#"
                },
                {
                    title: "Розвиток стратегії бренду, фірмового стилю",
                    url: "#"
                }
            ],
        },
        {
            title: "CONSULTING",
            services: [
                {
                    title: "Консультації з технологій",
                    url: "#"
                },
                {
                    title: "Навчання та тренінги",
                    url: "#"
                },
                {
                    title: "Аутсорс та Аутстафінг персоналу та команд",
                    url: "#"
                },
            ],
        }
    ],
    whyWeFeatures: [
        {
            title: "Інноваційні рішення:",
            text: "В нашій IT-компанії ми поєднуємо передові технології з креативним підходом, щоб пропонувати унікальні та інноваційні рішення. Ми постійно вдосконалюємося, слідуючи найновішим тенденціям у сфері технологій, щоб забезпечити нашим клієнтам перевагу на ринку."
        },
        {
            title: "Досвід та експертиза:",
            text: "Наша команда складається з висококваліфікованих фахівців, які мають роки досвіду в IT-галузі. Ми зосереджені на розробці якісних продуктів та послуг, використовуючи найкращі практики та методології."
        },
        {
            title: "Клієнтоорієнтованість:",
            text: "Ми розуміємо унікальні потреби кожного клієнта і працюємо над тим, щоб забезпечити індивідуалізовані рішення, які відповідають їхнім конкретним вимогам. Ваш успіх - наш головний пріоритет."
        },
        {
            title: "Гнучкість і масштабованість:",
            text: "Ми пропонуємо гнучкі рішення, які можуть масштабуватися відповідно до зростаючих потреб вашого бізнесу. Незалежно від розміру чи складності проекту, ми забезпечимо його ефективне виконання."
        },
        {
            title: "Надійність та безпека:",
            text: " Безпека та надійність - наші основні принципи. Ми забезпечуємо високий рівень безпеки у всіх наших продуктах та послугах, захищаючи конфіденційність та інтегрітет вашої інформації."
        },
        {
            title: "Відмінне обслуговування:",
            text: " Ми пишаємося нашим винятковим сервісом та підтримкою. Наша команда завжди готова допомогти та надати професійну підтримку у вирішенні будь-яких питань."
        }
    ],
    developmentAreas: [
        {
            title: "Фінансові Технології (FinTech)",
            url: "#"
        },
        {
            title: "E-commerce та Рітейл",
            url: "#"
        },
        {
            title: "Web3 та Децентралізовані Технології",
            url: "#"
        },
        {
            title: "Освіта (EdTech)",
            url: "#"
        },
        {
            title: "Логістика та Ланцюги Постачання",
            url: "#"
        },
        {
            title: "Ігрова Індустрія (Gaming)",
            url: "#"
        },
        {
            title: "Інтернет Речей (IoT)",
            url: "#"
        }
    ],
    howWeDoItList: [
        {
            image: "images/what-we-do-1.png",
            title: "ПЛАНУВАННЯ",
            points: [
                "Аналіз Вимог: Ми ретельно вивчаємо потреби та вимоги наших клієнтів. Це включає збір детальної інформації про проект, визначення бізнес-цілей та технічних завдань.",
                "Стратегія Розробки: Розробка плану проекту з чіткими цілями, етапами роботи та визначенням ресурсів."
            ]
        },
        {
            image: "images/what-we-do-2.png",
            title: "Дизайн / Прототип",
            points: [
                "Створення Прототипів: Розробка первинних прототипів продукту для візуалізації ідей та концепцій.",
                "Дизайн Інтерфейсів: Оформлення користувацьких інтерфейсів, які поєднують в собі зручність, функціональність та естетику."
            ]
        },
        {
            image: "images/what-we-do-3.png",
            title: "РОЗРОБКА",
            color: "#ADD6D5",
            points: [
                "Код і реалізація: Кодування та реалізація проекту використовують сучасні технології та методології. Забезпечуємо високу якість коду та його оптимізацію.",
                "Інтеграція систем: Інтеграція розробленого продукту з іншими системами або API."
            ]
        },
        {
            image: "images/what-we-do-4.png",
            title: "Тестування",
            points: [
                "Тестування: Комплексне, включаючи юніт, інтеграцію, безпеку, для безперебійної роботи продукту.",
                "Виправлення помилок: Аналіз тестування, виправлення для надійності та продуктивності."
            ]
        },
        {
            image: "images/what-we-do-5.png",
            title: "Запуск / Підтримка",
            points: [
                "Розгортання Продукту: Після успішного тестування ми здійснюємо запуск продукту, забезпечуючи його стабільну роботу."
            ]
        },
    ],
    footerSection: [
        // {
        //     title: "WEB3",
        //     links: [
        //         {
        //             title: "Пункт 1",
        //             link: "#"
        //         }
        //     ]
        // },
        // {
        //     title: "WEB2",
        //     links: [
        //         {
        //             title: "Пункт 1",
        //             link: "#"
        //         }
        //     ]
        // },
        // {
        //     title: "ЩЕ ОДНА СФЕРА",
        //     links: [
        //         {
        //             title: "Пункт 1",
        //             link: "#"
        //         }
        //     ]
        //
        // }
    ]
}
