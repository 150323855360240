import * as s from "./Button.module.scss"

import PropTypes from "prop-types"

import DefButton from "components/shared/buttons/DefButton"

/*
transparent - bool,
icon - SVG component,
color - white/orange,
children - children
*/

const Button = ({transparent= false, icon, color= "orange", className, children, ...otherProps}) => {
    const classNames = [
        s.btn,
        otherProps.className,
        s["btn_" + color],
        transparent ? s.transparent : "",
        className
    ].join(" ")

    return (
        <DefButton className={classNames} {...otherProps}>
            {children}
            {icon ? icon : null}
        </DefButton>
    )
}

Button.propTypes = {
    color: PropTypes.oneOf(["white", "orange"]),
    icon: PropTypes.element,
    transparent: PropTypes.bool
}

export default Button