import "styles/global.scss"

import {useEffect} from "react"

import {config} from "configs/scrollConfig"

// import SmoothScroll from "smoothscroll-for-websites"

import NavMenu from "components/widgets/NavMenu"

export default function Layout({ children }) {

    // useEffect(() => {
    //     SmoothScroll(config)
    //
    //     return () => SmoothScroll.destroy()
    // }, [])

    return (
        <>
            <NavMenu/>
            {children}
        </>
    )
}